<script setup>
    import { Link } from "@inertiajs/vue3"

    const props = defineProps({
        href: {
            type: String,
            required: true,
        },
        asVueLink: {
            type: Boolean,
            default: true,
        },
        only: {
            type: Array,
            default: [],
        },
        except: {
            type: Array,
            default: [],
        },
        externalLink: {
            type: Boolean,
            default: false,
        },
    })
</script>

<template>
    <Link
        v-if="props.asVueLink === true"
        :href="href"
        :only="props.only"
        :except="props.except"
        :target="externalLink ? '_blank' : '_self'"
        class="inline-flex items-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-brand-primary focus:ring-offset-2 active:bg-gray-900 disabled:cursor-not-allowed dark:bg-gray-200 dark:text-gray-800 dark:hover:bg-white dark:focus:bg-white dark:focus:ring-offset-gray-800 dark:active:bg-gray-300"
    >
        <slot />
    </Link>
    <a
        v-else
        :href="href"
        :target="externalLink ? '_blank' : '_self'"
        class="inline-flex items-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-brand-primary focus:ring-offset-2 active:bg-gray-900 disabled:cursor-not-allowed dark:bg-gray-200 dark:text-gray-800 dark:hover:bg-white dark:focus:bg-white dark:focus:ring-offset-gray-800 dark:active:bg-gray-300"
    >
        <slot />
    </a>
</template>
